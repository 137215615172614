<template>
  <div class="all-brands">
    <div class="right part-content-panel">
      <div class="panel-body" style="border: none;">
        <div class="bigsort">
          <table class="deftab" width="100%" id="tableSort1">
            <tbody>
            <tr>
              <th class="nowrap">品牌</th>
              <th >车系</th>
              <th :class="{SortDescCss:orderField=='modelYear' &&orderBy=='DESC',SortAscCss:orderField=='modelYear' &&orderBy=='ASC'}"
                  title="点击排序"
                  class="nowrap center"
                  @click="sort('modelYear')">年款
              </th>
              <th title="点击排序"
                  :class="{SortDescCss:orderField=='detail' &&orderBy=='DESC',SortAscCss:orderField=='detail' &&orderBy=='ASC'}"
                  @click="sort('detail')">车型
              </th>
              <th title="点击排序"
                  :class="{SortDescCss:orderField=='engineNo' &&orderBy=='DESC',SortAscCss:orderField=='engineNo' &&orderBy=='ASC'}"
                  @click="sort('engineNo')">发动机
              </th>
              <th title="点击排序"  class="center"
                  :class="{SortDescCss:orderField=='cc' &&orderBy=='DESC',SortAscCss:orderField=='cc' &&orderBy=='ASC'}"
                  @click="sort('cc')">排量
              </th>
              <th title="点击排序"
                  :class="{SortDescCss:orderField=='airIntake' &&orderBy=='DESC',SortAscCss:orderField=='airIntake' &&orderBy=='ASC'}"
                  @click="sort('airIntake')">进气形式
              </th>
              <th title="点击排序"  class="center"
                  :class="{SortDescCss:orderField=='fuelType' &&orderBy=='DESC',SortAscCss:orderField=='fuelType' &&orderBy=='ASC'}"
                  @click="sort('fuelType')">燃油类型
              </th>
              <th title="点击排序"  class="center"
                  :class="{SortDescCss:orderField=='effluentStandard' &&orderBy=='DESC',SortAscCss:orderField=='effluentStandard' &&orderBy=='ASC'}"
                  @click="sort('effluentStandard')">排放标准
              </th>
              <th title="点击排序"
                  :class="{SortDescCss:orderField=='transmissionDetail' &&orderBy=='DESC',SortAscCss:orderField=='transmissionDetail' &&orderBy=='ASC'}"
                  @click="sort('transmissionDetail')">变速箱
              </th>
              <th title="点击排序"
                  class="center"
                  :class="{SortDescCss:orderField=='gearNum' &&orderBy=='DESC',SortAscCss:orderField=='gearNum' &&orderBy=='ASC'}"
                  @click="sort('gearNum')">变速箱档位
              </th>
              <!--              <th title="点击排序" >配件目录</th>-->
            </tr>
            <tr v-for="(item,index) in sortedModelList" :key="index">
              <td class="nowrap">{{ item.brand }}</td>
              <td class="nowrap">{{ item.series }}</td>
              <td class="nowrap center">{{ item.modelYear }}</td>
              <td>{{ item.detail }}</td>
              <td>{{ item.engineNo }}</td>
              <td class="center">{{ item.cc }}</td>
              <td>{{ item.airIntake }}</td>
              <td class="center">{{ item.fuelType }}</td>
              <td class="center">{{ item.effluentStandard }}</td>
              <td>{{ item.transmissionDetail }}</td>
              <td class="center">{{ item.gearNum }}</td>
              <!--              <td style="color:#ccc" dataid="58501">无配件目录</td>-->
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarModels",
  props: {
    selectMode: {
      type: Boolean,
      default: false//list or select
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      series: {},
      modelList: [],
      orderField: '',
      orderBy: 'ASC'
    }
  },
  computed: {
    sortedModelList() {
      if (!this.orderField) {
        return this.modelList
      }
      let list = [].concat(this.modelList)
      list.sort((a, b) => {
        if (this.orderBy == 'ASC') {
          return a[this.orderField] < b[this.orderField] ? 1 : -1
        } else {
          return a[this.orderField] < b[this.orderField] ? -1 : 1
        }
      })
      return list;
    }
  },
  methods: {
    async seriesChanged(series) {
      this.series = series;
      let res = await this.$postJson('/brand/modelList', series);
      this.modelList = res.data || []
    },
    sort(field) {
      if (this.orderField === field) {
        this.orderBy = this.orderBy == 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.orderBy = 'ASC'
        this.orderField = field
      }
    }
  }
}
</script>

<style scoped lang="less">

.clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  clear: both;
}

.all-brands {
  .right .panel-body {
    padding: 0;
    min-height: 740px;
    border: #e8e8e8 1px solid;
    background-color: white;

    .deftab {
      margin-bottom: 10px;

      th {
        background-color: #F5F0F0;
        font-size: 15px;
        padding: 10px;
        font-weight: bold;
        text-align: left;
        cursor: pointer;
      }

      .SortDescCss {
        background-image: url(/static/images/Desc.gif);
        background-repeat: no-repeat;
        background-position: right center;
      }

      .SortAscCss {
        background-image: url(/static/images/Asc.gif);
        background-repeat: no-repeat;
        background-position: right center;
      }

      tr {
        border: solid 1px #ccc;
        line-height: 25px;
        text-align: left;
      }

      tr:hover {
        background-color: #ffe1d4;
      }

      td {
        padding: 10px;
        font-size: 14px;
        text-align: left;
      }

      .center{
        text-align: center;
      }
    }
  }
}
.nowrap{
  white-space: nowrap;
}
</style>

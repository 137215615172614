<template>
  <div class="all-brands">
    <div class="step_box_body2 clearfix">
      <div class="step_body_list2" :class="{scroll:selectMode}">
        <div class="series_box clearfix"
             v-for="group in groupByFactory" :key="group.factory"
        >
          <h2 class="series_box_title">{{ group.factoryName }}</h2>
          <div class="series_box_loop clearfix">
            <ul>
              <li v-for="(series,idx) in group.list" :key="idx" :title="series.seriesName" @click="clickSeries(series)"
                  :class="{selected:selectMode&&series.selected}">
                <span>{{
                    series.seriesName
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarSeries",
  props: {
    selectMode: {
      type: Boolean,
      default: false//list or select
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      brand: {},
      seriesList: []
    }
  },
  computed: {
    factoryList() {
      return Array.from(new Set(this.seriesList.map(v => v.factoryName))).sort();
    },
    groupByFactory() {
      let list = [].concat(this.factoryList);
      list = list.map(v => {
        let subList = [].concat(this.seriesList.filter(b => b.factoryName === v));
        return {
          factoryName: v,
          list: subList
        }
      })
      return list;
    }
  },
  mounted() {
  },
  methods: {
    async brandChanged(brand) {
      this.brand = brand;
      let res = await this.$get('/brand/seriesList', {brand: brand.brandName});
      let list = res.data || []
      list.forEach(v => v.selected = false);
      this.seriesList = list
    },
    clickSeries(series) {
      let targetSelected = !series.selected
      if (this.selectMode) {
        series.selected = targetSelected
      }
      if (targetSelected && !this.multiple) {
        this.seriesList.forEach(v => [
          v.selected = v === series
        ])
      }
      let retData = this.multiple ? this.seriesList.filter(v => v.selected) : series
      this.$emit("selectSeries", retData)
    }
  }
}
</script>

<style scoped lang="less">

.clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  clear: both;
}

.all-brands {

  .step_body_list2 {
    padding: 40px;

    &.scroll {
      height: 500px;
      overflow-y: auto;
    }
  }

  .series_box {
    margin-bottom: 20px;
  }

  .series_box_title {
    background: url(/images/carzone/step/step_icon2.png) left no-repeat;
    padding-left: 20px;
    font-size: 16px;
    color: #333333;
    font-weight: 100;
    margin-bottom: 10px;
  }

  .series_box_loop {

  }

  .series_box_loop ul {
    display: flex;
    flex-wrap: wrap;
    li {
      background: #F5F5F5;
      width: 200px; /* height:22px; line-height:22px; */
      overflow: hidden;
      margin-right: 10px;
      border: 1px #DFDFDF solid;
      padding: 5px 5px;
      text-align: center;
      float: left;
      margin-bottom: 10px;
      font-size: 14px;
      cursor: pointer;

      &.selected {
        //background-color: #3c763d;
        border: 3px red solid;

        &:hover {
          border: 3px red solid;
        }
      }

      span {
        color: #333;
        font: 14px/1.2 'Microsoft YaHei', "hiragino sans gb", '黑体', '宋体', Arial, Lucida Grande, Tahoma, sans-serif;
      }
    }

    li:hover {
      border: 1px #E16B05 solid;
    }
  }
}
</style>

<template>
  <div class="all-brands">
    <div class="step_box">
      <div class="step_box_body clearfix">
        <div class="step_body_menu">
          <div class="step_body_menu_box clearfix">

            <!--            <ul>-->
            <!--              <li v-for="item in alphaList" :class="{in:item==curAlpha}" :key="item">-->
            <!--                <a :href="'#slist_'+item">{{ item }}</a>-->
            <!--              </li>-->
            <!--            </ul>-->
            <!--              <span>快速查找:</span>-->
            <a-anchor :affix="true" @change="anchorChanged" :offsetTop="offsetTop">
              <a-anchor-link v-for="item in alphaList" :class="{in:item==curAlpha}" :key="item" :href="'#slist_'+item"
                             :title="item"/>
            </a-anchor>
          </div>
        </div>


        <div class="step_body_list" :class="{scroll:selectMode}">
          <div v-for="(group,index) in groupByChar" class="abc_box clearfix" :id="'slist_'+group.alpha" :key="index">
            <div class="abc_box_title"><strong>{{ group.alpha }}</strong></div>
            <div class="abc_box_right clearfix">
              <ul>
                <li class="first" v-for="(brand,idx) in group.list" :key="idx"
                    :class="{selected:selectMode && brand.selected}">
                  <a @click="clickBrand(brand)">
                    <div class="abc_li clearfix">
                      <div class="abc_li_icon">
                        <img style="width:70px; height: 50px;"
                             :src="brand.brandIcon||'/static/images/default-brand-icon.jpg'"
                             @error="imgOnError">
                      </div>
                      <div class="abc_li_name">{{ brand.brandName }}</div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarBrands",
  props: {
    selectMode: {
      type: Boolean,
      default: false//list or select
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      brandList: [],
      curAlpha: 'A',
      offsetTop: 58,
    }
  },
  computed: {
    alphaList() {
      return Array.from(new Set(this.brandList.map(v => v.firstChar))).sort();
    },
    groupByChar() {
      let list = [].concat(this.alphaList);
      list = list.map(v => {
        let subList = [].concat(this.brandList.filter(b => b.firstChar === v));
        return {
          alpha: v,
          list: subList
        }
      })
      return list;
    }
  },
  mounted() {
    this.getAllBrand();
  },
  methods: {
    getAllBrand() {
      this.$get('/brand/allList').then(res => {
        let list = res.data || []
        list.forEach(v => v.selected = false);
        this.brandList = list
      })
    },
    resetIndex() {
      this.offsetTop = this.offsetTop == 58 ? 59 : 58
    },
    imgOnError(e) {
      e.target.src = '/static/images/default-brand-icon.jpg'
    },
    anchorChanged(e) {
      console.log(e)
    },
    clickBrand(brand) {
      let targetSelected = !brand.selected
      if (this.selectMode) {
        brand.selected = targetSelected
      }
      if (targetSelected && !this.multiple) {
        this.brandList.forEach(v => [
          v.selected = v === brand
        ])
      }
      let retData = this.multiple ?  this.brandList.filter(v => v.selected):brand
      this.$emit("selectBrand", retData)
    }
  }
}
</script>

<style scoped lang="less">

.clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  clear: both;
}

.all-brands {
  .step_box {
    .step_box_body {
      padding: 0px 40px;
      position: relative;
    }

    .step_body_menu_box {
      padding: 5px 40px 20px 0;

      span {
        display: block;
        float: left;
        font-size: 16px;
        margin-right: 10px;
        padding: 5px 0px;
      }

      ul {
        margin-left: 20px;
        color: #333;

        li {
          float: left;
          margin-right: 1px;
          list-style: none;

          a {
            display: block;
            background: #FFF;
            padding: 5px 10px;
            font-size: 16px;
            font-weight: 100;
            text-decoration: none;

            text-decoration: none;
            font-family: Arial, Helvetica, sans-senif, SimSun, "宋体";
          }

          a:hover {
            color: #F15921;
            text-decoration: none;
          }

          .in a {
            background: #F5811F;
            color: #FFF;
          }
        }
      }
    }

  }


  .step_body_list {
    &.scroll {
      height: 500px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  /*abc_box*/

  .abc_box {
    padding-top: 60px;
    margin-top: -50px;
  }


  .abc_box_title {
    float: left;
    width: 100px;
    padding-top: 5px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .abc_box_title strong {
    display: inline-block;
    padding: 2px 10px;
    font-weight: 100;
    color: #656565;
    font-size: 20px;
    background: #F8F8F8;
    border-radius: 5px;
    border: 1px #D6D5D5 solid;
  }

  .abc_box_right {
    margin-left: 60px;
  }

  .abc_box_right ul {
    margin-right: -10px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }

  /*added by wenjie 2014-12-28*/

  .abc_box_right ul li {
    border: 1px #DFDFDF solid;
    cursor: pointer;
    float: left;
    margin-right: 10px /*modified by wenjie 2014-12-28*/;
    margin-bottom: 20px;

    &.selected {
      //background-color: #3c763d;
      border: 3px red solid;

      .abc_li .abc_li_name {
        //color:#FFF;
      }

      &:hover {
        border: 3px red solid;
      }
    }
  }

  .abc_box_right ul li a {
    text-decoration: none;
  }

  /*.abc_box_right ul li.first{ margin-left:0;}deleted by wenjie 2014-12-28*/

  .abc_box_right ul li:hover {
    border: 1px #E16B05 solid;
  }

  .abc_box_right ul li.checked {
    border: 1px #E16B05 solid;
    /*background: url(/images/carzone/selected.png) right bottom no-repeat;*/
  }

  .abc_li {
    padding: 5px;
    list-style: none;
  }

  .abc_li .abc_li_icon {
    float: left;
    text-align: left;
    border-right: 1px #f5f2f2 solid;
  }

  .abc_li .abc_li_icon img {
    height: 30px;
    width: auto;
  }

  .abc_li .abc_li_name {
    margin-left: 50px;
    padding: 5px;
    text-align: center;
    line-height: 44px;
    height: 44px;
    overflow: hidden;
    color: #333;
  }
}

/deep/ .ant-anchor-wrapper {
  //position: fixed;
  //top: 0;
  //left: 0;
  //right: 0;
  //z-index: 10;

  .ant-anchor {
    display: flex;
    padding: 10px 10px 10px 80px;
    border-bottom: 1px solid #ccc;

    & > :first-child {
      width: 50px;

      &::before {
        content: '快速查找：';
        white-space: nowrap;
        line-height: 50px;
        font-size: 16px;
        display: inline;
        background-color: #FFF;
      }
    }

    .ant-anchor-ink-ball {
      display: none !important;
    }
  }

  .ant-anchor-link {
    text-align: center;
    padding: 7px 8px 7px 8px;

  }

  .ant-anchor-link-title {
    //font-weight: 700;
    font-size: 16px;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-senif, SimSun, "宋体";
  }

  .ant-anchor-link-active {
    background-color: #F5811F;

    .ant-anchor-link-title-active {
      color: #FFF;
    }
  }
}
</style>
